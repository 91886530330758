import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import DescriptionIcon from '@mui/icons-material/Description';
import QueuePlayNextIcon from '@mui/icons-material/QueuePlayNext';
import { makeStyles, Trigger } from '@geomagic/core';
import { getEntityClass, getReference } from '@geomagic/geonam';
import { i18n } from '@geomagic/i18n';
import StackedDialog from '@geomagic/nam-react-core/components/StackedDialog';
import { useStickySessionState } from '@geomagic/nam-react-core/utils';
import AccordionItem from '@components/AccordionItem';
import Dispatch from '@components/Dispatch';
import AddDraftTrigger from '@components/Dispatch/AddDraftTrigger';
import Placeholder from '@components/Placeholder';
import { CREATE_DISPATCHES_KEY } from '@consts';
import { ENTITY_SELECTOR_KEY } from '@database/consts';
import { CLASSNAME_DISPATCH } from '@graphql/consts';
import getDatabaseDocRelations from './utils/getDatabaseDocRelations';
import EditFunclocTriggers from '../Funcloc/EditFunclocTriggers';
import Progress from './Progress';

const useStyles = makeStyles()(({ breakpoints, palette, spacing }) => ({
  root: {
    [breakpoints.down('sm')]: {
      borderRadius: '0 !important',
      borderLeft: 'none',
      borderRight: 'none',
    },
  },
  summary: {
    marginLeft: spacing(0.5),
    marginRight: spacing(0.5),
    padding: spacing(0, 1),
    '&$disabled': {
      backgroundColor: alpha(palette.background.default, palette.action.disabledOpacity),
      opacity: 1,
    },
    [breakpoints.down('sm')]: {
      minHeight: '56px !important',
    },
  },
  detail: {
    padding: spacing(1, 2),
    [breakpoints.down('sm')]: {
      borderRadius: 0,
      padding: spacing(0, 1),
    },
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  typography: {
    '&$disabled': {
      opacity: palette.action.disabledOpacity,
    },
  },
  triggerGroup: {
    [breakpoints.down('md')]: {
      marginLeft: spacing(),
    },
  },
  trigger: {
    whiteSpace: 'noWrap',
    '&$disabled': {
      pointerEvents: 'auto',
    },
  },
  disabled: {},
}));

const FormElementBlock = (props) => {
  const {
    childRef,
    children,
    context,
    data,
    doc: assignmentDoc,
    expanded,
    infoTrigger,
    onEditNewFuncloc,
    onRemoveFuncloc,
    onSelect,
    parentPath,
    path,
    ...accordionProps
  } = props;

  const itemRef = useRef(null);
  const {
    allRelevantDispatches = [],
    client,
    entityClasses,
    getPreviousMap,
    isMobile,
    isOnline,
    isReadOnly,
    mapProps,
    onUpdateRelevantDispatches,
    user,
  } = context;

  const {
    description,
    dispatchesCreatable,
    dispatchCreationConfig,
    fromTemplate = false,
    id,
    name,
    relevantDispatches = [],
  } = data;

  const assignment = assignmentDoc.getPatchedEntity();
  const hasDispatchCreationConfig = !!dispatchCreationConfig;

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [, setCreateDispatchesMode] = useStickySessionState(CREATE_DISPATCHES_KEY);

  const disabled = children?.length < 1;
  const docRelations = getDatabaseDocRelations(assignmentDoc, data, path);
  const entityClassDispatch = getEntityClass(entityClasses, CLASSNAME_DISPATCH);
  const userId = user.id;

  const mergedRelevantDispatches = [
    ...relevantDispatches,
    ...allRelevantDispatches.filter((dispatch) => dispatch?.blockId === id).map((item) => getReference(item)),
  ];

  const hasRelevantDispatches = mergedRelevantDispatches.length > 0;
  const { classes } = useStyles(props);

  const dispatchListLabel = i18n.t('button.dispatches', {
    variables: { amount: mergedRelevantDispatches?.length || '0' },
  });

  /**
   *  CLASSES
   */

  const classDisabled = { [classes.disabled]: disabled };
  const classExpanded = { [classes.expanded]: expanded };

  const accordionItemClasses = {
    root: classNames(classes.root, classExpanded),
    summary: classNames(classes.summary, { ...classDisabled, ...classExpanded }),
    detail: classes.detail,
    subtitle: classNames(classes.typography, classDisabled),
  };

  const triggerClasses = classNames(classes.trigger, classDisabled);

  /**
   *  DATABASE
   */

  const queryProps = {
    [ENTITY_SELECTOR_KEY]: { $in: mergedRelevantDispatches.map(({ id: dispatchId }) => dispatchId) },
  };

  /**
   *  EVENT HANDLER
   */

  const handleAddedDraft = async (createdDispatch) => {
    const newDispatch = getReference(createdDispatch);

    await onUpdateRelevantDispatches({ newDispatch, blockId: id });
  };

  const handleCloseDialog = () => setDialogOpen(false);

  const handleOpenModal = (event) => {
    event.stopPropagation();

    setDialogOpen(true);
  };

  const handleDispatchCreationMode = () => {
    setCreateDispatchesMode({
      assignmentId: assignment.id,
      dispatchCreationConfig,
      formElementBlockId: id,
      path,
      docRelations,
    });
  };

  const handleEditBlock = () => {
    onEditNewFuncloc({
      parentPath,
      path,
    });
  };

  const handleRemoveBlock = () => {
    onRemoveFuncloc({ path, removeId: id });
  };

  /**
   *  COMPONENTS
   */

  const DispatchTriggerComponent =
    dispatchesCreatable || mergedRelevantDispatches?.length > 0 ? (
      <ButtonGroup className={classes.triggerGroup} color="primary" disableElevation size="medium" variant="contained">
        <Button className={triggerClasses} onClick={handleOpenModal} size="small">
          {dispatchListLabel}
        </Button>
        {dispatchesCreatable && !isReadOnly ? (
          hasDispatchCreationConfig ? (
            <Trigger
              className={triggerClasses}
              color="primary"
              icon={<QueuePlayNextIcon />}
              onClick={handleDispatchCreationMode}
              variant="contained"
            />
          ) : (
            <AddDraftTrigger
              className={triggerClasses}
              docRelations={docRelations}
              entityClass={entityClassDispatch}
              entityClasses={entityClasses}
              getPreviousMap={getPreviousMap}
              isMobile={isMobile}
              mapProps={mapProps}
              onAddedDraft={handleAddedDraft}
              user={user}
              userId={userId}
              variant="contained"
            />
          )
        ) : null}
      </ButtonGroup>
    ) : null;

  const TitleComponent = (
    <div className={classes.titleContainer}>
      <Typography className={classNames(classes.typography, classDisabled)} component="div" noWrap variant="h6">
        {name}
      </Typography>
      {infoTrigger &&
        React.cloneElement(infoTrigger, {
          className: classNames(infoTrigger?.props?.className, triggerClasses),
        })}
      {fromTemplate && (
        <EditFunclocTriggers
          disableDelete={hasRelevantDispatches}
          onEditNewFuncloc={handleEditBlock}
          onDeleteFuncloc={handleRemoveBlock}
        />
      )}
    </div>
  );

  const DialogContent = (
    <Dispatch
      areMapActionsDisabled={true}
      client={client}
      dispatchCreationConfig={dispatchCreationConfig}
      docRelations={docRelations}
      entityClasses={entityClasses}
      isDetail={true}
      isDocumentsHidden={false}
      isGrouped={true}
      isMobile={isMobile}
      isOnline={isOnline}
      isReadOnly={isReadOnly}
      listPlaceholderComponent={
        <Placeholder
          icon={<DescriptionIcon />}
          title={i18n.t('dispatch.placeholder.noDrafts.title')}
          subtitle={i18n.t('dispatch.placeholder.noDrafts.subtitle')}
        />
      }
      getPreviousMap={getPreviousMap}
      mapProps={mapProps}
      onAddedDraft={handleAddedDraft}
      queryProps={queryProps}
      user={user}
    />
  );

  return (
    <>
      <AccordionItem
        classes={accordionItemClasses}
        disabled={disabled}
        expanded={expanded}
        id={data.id}
        onSelect={onSelect}
        panelAction={DispatchTriggerComponent}
        panelIcon={<Progress forms={[data]} variant="icon" />}
        ref={itemRef}
        title={TitleComponent}
        {...(description && !infoTrigger && { subtitle: description })}
        {...accordionProps}
      >
        {children}
      </AccordionItem>
      <StackedDialog
        content={DialogContent}
        isFullscreen={true}
        handleClose={handleCloseDialog}
        open={isDialogOpen}
        title={i18n.t('dispatches.dialog.title', { variables: { name } })}
      />
    </>
  );
};

FormElementBlock.propTypes = {
  childRef: PropTypes.object,
  children: PropTypes.node,
  context: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  doc: PropTypes.object,
  expanded: PropTypes.bool,
  infoTrigger: PropTypes.node,
  onEditNewFuncloc: PropTypes.func,
  onRemoveFuncloc: PropTypes.func,
  onSelect: PropTypes.func,
  parentPath: PropTypes.string,
  path: PropTypes.string,
};

export default FormElementBlock;
