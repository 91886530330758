import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import Badge from '@mui/material/Badge';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { makeStyles, Trigger } from '@geomagic/core';
import { i18n } from '@geomagic/i18n';
import StackedDialog from '@geomagic/nam-react-core/components/StackedDialog';
import { getEntityClass } from '@geomagic/geonam';
import useAppBar from '@components/AppBar/useAppBar';
import FormComponent, { checkFormIsFinished } from '@components/FormComponent';
import FunclocWizard from '@components/Funcloc/FunclocWizard';
import useFeatures from '@components/Map/utils/useFeatures';
import { FORM_PATH, FORM_TYPENAME, MOBILE_TRIGGER_SIZE } from '@consts';
import getPatches from '@database/getPatches';
import { CLASSNAME_FUNCLOC, RELEVANT_DISPATCH_PATH } from '@graphql/consts';
import usePrevious from '@utils/usePrevious';
import getFeatures from './getFeatures';

const useStyles = makeStyles()(({ breakpoints, palette }) => ({
  badge: {
    backgroundColor: palette.status.CLOSED,
  },
  trigger: {
    [breakpoints.down('md')]: {
      height: MOBILE_TRIGGER_SIZE,
      width: MOBILE_TRIGGER_SIZE,
    },
  },
}));

const AssignmentDetail = (props) => {
  const {
    assignment,
    client,
    entityClasses,
    getFeatureStyle,
    getPreviousMap,
    isMobile,
    isOnline,
    mapProps,
    onOpenTasks,
    stateRef,
    user,
  } = props;

  const { classes } = useStyles();

  const entity = assignment.getPatchedEntity();
  const { displayName, forms, processInstances = [], relevantDispatches } = entity;

  const filteredForms = forms.filter(({ usedAsTemplate }) => !usedAsTemplate);
  const templateForms = forms.filter(({ usedAsTemplate }) => usedAsTemplate);
  const { mapRef, selectColor, maxExtentZoomLevel } = mapProps;
  const maxSteps = filteredForms?.length;
  const isFormFinished = checkFormIsFinished(filteredForms);
  const tasks = flatten(processInstances.map((processInstance) => processInstance?.tasks));
  const hasTasks = tasks.length > 0;

  const isAssignedToCurrentUser = tasks.map((task) => task?.assignee).includes(user.loginName);
  const isCloseable = hasTasks && isFormFinished && isOnline;
  const isClosed = entity.processingStatus.includes('CLOSED');
  const isReadOnly = isClosed || !isAssignedToCurrentUser;

  const { setActions, setTitle } = useAppBar();
  const [activeEditField, setActiveEditField] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [visibleLocationRecordList, setVisibleLocationRecordList] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [wizardData, setWizardData] = useState();

  const entityClassFuncloc = getEntityClass(entityClasses, CLASSNAME_FUNCLOC);

  const features = useMemo(() => getFeatures([assignment]), [assignment]);

  const activeForm = filteredForms[activeStep];
  const activeFormIndex = forms.findIndex((form) => form.id === activeForm.id);
  const featureCollections = activeForm?.featureCollections;
  const formPath = `${FORM_PATH}/${activeFormIndex}`;

  const prevFeatureCollections = usePrevious(featureCollections);

  /**
   *  EVENT HANDLER
   */

  const handleClickBack = useCallback(() => {
    setActiveStep((prevStep) => prevStep - 1);
  }, []);

  const handleClickForward = useCallback(() => {
    setActiveStep((prevStep) => prevStep + 1);
  }, []);

  const handleChangeMenu = useCallback((event, index) => {
    setActiveStep((prevStep) => {
      return index !== prevStep ? index : prevStep;
    });
  }, []);

  const handleUpdateFormElement = (value, path, data) => {
    const newPatch = {
      op: 'replace',
      path,
      value: data ? { ...data, ...value } : value,
    };

    handleUpdatePatch(newPatch);
  };

  const handleUpdatePatch = async (newPatch) => {
    const { jsonPatch } = assignment;

    await assignment.atomicUpdate((oldData) => {
      const newJsonPatch = getPatches(jsonPatch, newPatch);
      oldData.jsonPatch = newJsonPatch;
      return oldData;
    });
  };

  const handleEditNewFuncloc = (data) => {
    setWizardData(data);
    setDialogOpen(true);
  };

  const handleRemoveFuncloc = async ({ path, removeId }) => {
    const newPatch = {
      op: 'remove',
      path,
      removeId,
    };
    await handleUpdatePatch(newPatch);
  };

  const handleUpdateRelevantDispatches = ({ newDispatch, blockId }, additionalPatch) => {
    const newRelevantDispatches = [
      ...relevantDispatches,
      { ...newDispatch, closed: false, ...(blockId && { blockId }) },
    ];

    const newPatch = {
      op: 'replace',
      path: RELEVANT_DISPATCH_PATH,
      value: newRelevantDispatches,
    };

    handleUpdatePatch(newPatch);
  };

  const handleCloseDialog = () => setDialogOpen(false);

  /**
   *  EFFECTS
   */

  const { selectFeatures } = useFeatures({
    mapRef,
    features,
    isSelectable: false,
    maxExtentZoomLevel,
    selectColor,
    style: getFeatureStyle,
  });

  useEffect(() => {
    setTitle(displayName);
  }, [displayName, setTitle]);

  useEffect(() => {
    const handleOpenTasks = (event) => {
      event.stopPropagation();
      onOpenTasks && onOpenTasks(assignment);
    };

    const triggerProps = {
      className: classes.trigger,
      color: 'inherit',
    };

    setActions(
      <Trigger
        {...triggerProps}
        icon={
          <Badge classes={{ badge: classes.badge }} invisible={!isCloseable} variant="dot">
            <ListAltIcon />
          </Badge>
        }
        onClick={handleOpenTasks}
      />
    );

    return () => {
      setActions(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCloseable]);

  useEffect(() => {
    if (featureCollections && !isEqual(prevFeatureCollections, featureCollections)) {
      const featuresToSelect = featureCollections.length ? featureCollections[0]?.features : null;
      featuresToSelect && selectFeatures(featuresToSelect);
    }
  }, [featureCollections, prevFeatureCollections, selectFeatures]);

  /**
   *  FORM CONTEXT
   */

  const context = {
    activeEditField,
    allRelevantDispatches: relevantDispatches,
    client,
    entityClasses,
    getPreviousMap,
    isMobile,
    isOnline,
    isReadOnly,
    mapProps,
    mapRef,
    onUpdate: handleUpdateFormElement,
    onUpdateRelevantDispatches: handleUpdateRelevantDispatches,
    stateRef,
    user,
    visibleLocationRecordList,
    setActiveEditField,
    setVisibleLocationRecordList,
  };

  /**
   *  COMPONENTS
   */

  const DialogContent = wizardData ? (
    <FunclocWizard
      assignmentDoc={assignment}
      entityClass={entityClassFuncloc}
      entityClasses={entityClasses}
      getPreviousMap={getPreviousMap}
      handleClose={handleCloseDialog}
      isMobile={isMobile}
      isOnline={isOnline}
      mapProps={mapProps}
      onChange={handleUpdatePatch}
      user={user}
      wizardData={wizardData}
    />
  ) : null;

  return (
    <>
      <FormComponent
        activeStep={activeStep}
        context={context}
        data={{ ...activeForm, typename: FORM_TYPENAME }}
        disableBack={activeStep === 0}
        disableForward={activeStep === maxSteps - 1}
        doc={assignment}
        entityClasses={entityClasses}
        forms={filteredForms}
        templateForms={templateForms}
        getPreviousMap={getPreviousMap}
        id={activeForm?.id}
        mapProps={mapProps}
        onChangeMenu={handleChangeMenu}
        onClickBack={handleClickBack}
        onClickForward={handleClickForward}
        onEditNewFuncloc={handleEditNewFuncloc}
        onRemoveFuncloc={handleRemoveFuncloc}
        path={formPath}
        user={user}
      />
      <StackedDialog
        content={DialogContent}
        isFullscreen={true}
        handleClose={handleCloseDialog}
        open={isDialogOpen}
        title={i18n.t('funcloc.dialog.title', {
          variables: {
            name: entity.displayName,
          },
        })}
      />
    </>
  );
};

AssignmentDetail.propTypes = {
  assignment: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  entityClasses: PropTypes.array.isRequired,
  getFeatureStyle: PropTypes.func.isRequired,
  getPreviousMap: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isOnline: PropTypes.bool,
  mapProps: PropTypes.object.isRequired,
  onOpenTasks: PropTypes.func.isRequired,
  stateRef: PropTypes.object.isRequired,
  user: PropTypes.object,
};

export default AssignmentDetail;
